let counties = [
    "Alameda",
    "Alpine",
    "Amador",
    "Butte",
    "ContraCosta",
    "Calaveras",
    "DelNorte",
    "ElDorado",
    "Fresno",
    "Humboldt",
    "Imperial",
    "Inyo",
    "Kern",
    "Kings",
    "Lake",
    "Lassen",
    "LosAngeles",
    "Marin",
    "Mendocino",
    "Madera",
    "Merced",
    "Modoc",
    "Monterey",
    "Mono",
    "Napa",
    "Nevada",
    "Orange",
    "Placer",
    "Plumas",
    "Riverside",
    "Sacramento",
    "SanBernardino",
    "SanDiego",
    "SanFrancisco",
    "SanJoaquin",
    "SanLuisObispo",
    "SanMateo",
    "SanBenito",
    "SantaBarbara",
    "SantaClara",
    "SantaCruz",
    "Shasta",
    "Solano",
    "Sonoma",
    "Sierra",
    "Siskiyou",
    "Stanislaus",
    "Sutter",
    "Tulare",
    "Tehama",
    "Trinity",
    "Ventura",
    "Yolo",
    "Yuba",
]

export default counties