import React from 'react'

function District7() {
    return (
        <div>
            <p>(District 7)</p>
            <br />
            <a href='https://caltrans.maps.arcgis.com/apps/webappviewer/index.html?id=23b2abcf3d6b4ae0be7a579258141178' target={"_blank"} rel='noreferrer'>District 7 Map Index</a>&nbsp;&nbsp;&nbsp;(Updated)
            <br />
            <a href='https://www.arcgis.com/home/webmap/viewer.html?webmap=be47fd3f5cc349f28f2871b5a6c07c6f&extent=-118.7232,33.7525,-117.538,34.5967' target={"_blank"} rel='noreferrer'>District 7 GIS</a>
            <br />
            <br />
            <h4>Request ROW Maps by Email:</h4>
            <p>Attach <strong><a href='https://postmile.dot.ca.gov/PMQT/PostmileQueryTool.html?' target={'_blank'} rel='noreferrer'>Postmile Info</a></strong></p>
            <br />
            <h4>Contact:</h4>
            <p>Right of Way Engineering Staff | <a href='mailto:D7.RWEngineering@dot.ca.gov'>D7.RWEngineering@dot.ca.gov</a> | (213) 266-3625</p>
        </div>
    )
}

export default District7
